














































import {Vue, Component, PropSync} from 'vue-property-decorator';
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import User from "@/models/User";

@Component({})
export default class UserFileTab extends Vue {

  @PropSync('user') syncedUser!: User

  lang: any = getModule(LangModule).lang

  updateUser() {
    this.$emit("update")
  }

}
